import Timeline from "../Timeline";
import educationList from "./educationList";
const Education = () => {
  return (
    <>
      <Timeline title={"Education"} timelineList={educationList} />
    </>
  );
};
export default Education;
