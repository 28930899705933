import Timeline from "../Timeline";
import workExperienceList from "./workExperienceList";
const WorkExperience = () => {
  return (
    <>
      <Timeline title={"Experience"} timelineList={workExperienceList} />
    </>
  );
};
export default WorkExperience;
